<template>
  <v-app id="inspire">
    <div  >
      <div class="filter-informe-eventos">
        <div class="container-title-informe">
          <h3><i class="fas fa-chart-line pr-2"></i> Generar informe de Control de Acceso</h3>
        </div>
        <div class="col-12 col-md-8">
          <v-select
            v-model="evento"
            item-value="id"
            item-text="nombre"
            :items="$store.state.informes.eventosInforme"
            label="Evento"
          ></v-select>
        </div>
        <div class="col-12 col-md-4 d-flex flex-wrap align-items-center">
          <button class="btn btn-primary" :disabled="!(evento != null)" @click="getInformeDeEventos()">Generar</button>
        </div>
      </div>


      <div  v-if="$store.state.informes.tableEventoInforme"  >
      <template >
	
            <!-- Widgets -->
            <div class="row" >
               
                <div class="col-lg-6 col-sm-6">
                    <div class="counter-box text-center white">
                        <div class="text font-17 m-b-5">AFORO INGRESADO</div>
                        <h3 class="m-b-10">{{ formatoNumero(tingreso) }}  </h3>
                        <div class="icon">
                          <i class="material-icons col-green">trending_up</i>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="counter-box text-center white">
                        <div class="text font-17 m-b-5"> % AFORO  INGRESADO  </div>
                        <h3 class="m-b-10">{{ formatoNumero(tporcetaje) }}  %   </h3>
                        <div class="icon">
                          <i class="material-icons col-green">trending_up</i>
                        </div>
                    </div>
                </div>
                </div> 
                <div class="card">
            <div class="header">
                            <h2>
                                <strong>AFORO INGRESADO</strong> EVENTO</h2>
                             
                        </div>
                        <div class="body">

            <table class="  table">
              <thead>
                <tr style=" font-size: 16px;">
                  <th    style=" background:#F4F4F4; font-weight:bold;">  TRIBUNA </th> 
                  <th    style=" background:#F4F4F4; font-weight:bold;">  LOCALIDAD </th> 
                  <th   style=" background:#F4F4F4; font-weight:bold; text-align:right;">  AFORO VENDIDO   </th> 
                  <th   style=" background:#F4F4F4; font-weight:bold; text-align:right;">  TOTAL INGRESADO   </th> 
                  <th   style=" background:#F4F4F4; font-weight:bold; text-align:right;">  % AFORO  INGRESADO  </th> 
                 </tr>
                </thead>
                <tr  v-for="item in $store.state.informes.tableEventoInforme.aforonew" >
                  <td>  
                    <div class="p-t-10">
                                <span class="pull-left progress-label"> {{ item.tribuna }} </span>
                              
                            </div>
                             </td> 
                              <td>  
                    <div class="p-t-10">
                                <span class="pull-left progress-label"> {{ item.localidad }} </span>
                              
                            </div>
                             </td> 
                  <td  style="text-align:right;" >  {{  formatoNumero( item.Pagos_total) }} </td> 
                  <td  style="text-align:right;" >   <span class="pull-right progress-percent label label-info m-b-5"> {{   formatoNumero(item.ingresos) }} </span> </td>
                  <td  style="text-align:right;" >    <span class="pull-right progress-percent label label-info m-b-5">{{   formatoNumero(( parseFloat(item.ingresos) / parseFloat(item.Pagos_total)) *100) }} %</span> </td> 
                </tr>

                <tr   >
                  <td  style=" background:#DFFFEF; font-weight:bold;" >   TOTAL </td> 
                  <td  style=" background:#DFFFEF; font-weight:bold;" >     </td> 
                  <td  style=" background:#DFFFEF; font-weight:bold;text-align:right;" >  <span class="label bg-green shadow-style">{{ formatoNumero(tvendido) }} </span> </td> 
                  <td  style=" background:#DFFFEF; font-weight:bold;text-align:right;" >  <span class="label bg-green shadow-style"> {{  formatoNumero(tingreso) }} </span> </td> 
                  <td  style=" background:#DFFFEF; font-weight:bold;text-align:right;" >  <span class="label bg-green shadow-style">{{  formatoNumero(tporcetaje) }}  % </span> </td>                
               </tr>
            </table>
          </div>
        </div>
        </template>
       
      </div>
    </div>
  </v-app>
</template>

<script>  
import numeral from 'numeral';
export default { created(){
           document.title = 'Dashboard - TicketShop';
           $(document).ready(function(){
            pagesIndex();
            jqueryKnob();
            sparkline();
            carousel();
            });
        },
  data() {
    return {
      evento: null,
      tingreso:0,
      tporcetaje:0,
      tvendido:0
     }
  },
  computed: {
    listadoLocalidades(){
      return this.evento ? this.$store.state.informes.eventosInforme.find((e) => e.id == this.evento).localidades : []
    },
    listadoPuntosVenta(){
      return this.evento ? this.$store.state.informes.eventosInforme.find((e) => e.id == this.evento).puntos_venta : []
    },
  },
  mounted(){
    this.$store.state.informes.tableEventoInforme = null; 
    this.$store.state.informes.taforo = null; 
    this.$store.state.informes.tboletas = null; 
    this.$store.state.informes.tporcentaje=null;
    this.$store.state.informes.aforohabilitado=null;
    this.$store.dispatch('informes/getEventos');
    document.title = 'Informe Aforo evento - TicketShop';
  },
  methods:{
    formatoNumero(numero) {
      return numeral(numero).format('0,0');
    },
    async getInformeDeEventos(){
      if(this.evento){
        this.tingreso=0;
        this.tporcetaje=0;
        this. tvendido=0;
        await this.$store.dispatch('informes/getInformesAforoEvento', {
          id_evento: this.evento,
        });
        this. $store.state.informes.tableEventoInforme.aforonew.forEach((item, index) => {
          
          this.tingreso= this.tingreso+parseFloat(item.ingresos); 
          this.tvendido= this.tvendido+parseFloat(item.Pagos_total);  
 
        });
        this.tporcetaje= this.tingreso/ this.tvendido*100

      }
    }
  }
}
</script>

 


<style>
  .filter-informe-eventos{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 5px 1px rgb(219, 219, 219);
  }
  .filter-informe-eventos .v-menu__content{
    top: 35px !important;
    left: 0 !important;
  }
  .container-title-informe{
    width: 100%;
    border-bottom: 1px solid #cacaca;
    margin-bottom: 2rem;
  }
  .v-date-picker-table{
    height: 100% !important;
  }
  .v-table__overflow{
    max-height: none !important;
    overflow-y: hidden !important;
  }
  .v-datatable thead tr th:first-child{
    min-width: 300px;
  }
</style>